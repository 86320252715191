import moment from "moment"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AddService } from "./add-service"
import { PlusCircleIcon, Cog8ToothIcon } from "@heroicons/react/24/outline"
import { Settings } from "./settings"
import { GoogleMigrator } from "./google-auth-import"
import toast, { Toaster } from "react-hot-toast"
const totpGenerator = require("totp-generator")

export interface Service {
  name: string
  id: string
  secret: string
  issuer: string
  folder: string
}

function getServices() {
  const storedService = localStorage.getItem("services")
  try {
    if (storedService) {
      const parsed = JSON.parse(storedService)
      return parsed
    }
  } catch (err) {
    console.error(err)
  }
}

function getTimeToNextRefresh(): number {
  const second = moment().second()
  const next =
    second > 30
      ? moment().add(1, "minute").startOf("minute")
      : moment().startOf("minute").add(30, "seconds")
  const diff = next.diff(moment(), "milliseconds")

  return Math.floor(diff / 1000)
}

export function App() {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const timebarRef = useRef<HTMLDivElement | null>(null)

  const [services, setServices] = useState<Service[]>(getServices() ?? [])
  const [codes, setCodes] = useState<{ code: string; name: string }[]>([])
  const [showAdd, setShowAdd] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const [timeLeft, setTimeLeft] = useState<number | null>(
    getTimeToNextRefresh()
  )
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)

  const folders = useMemo(() => {
    return services
      .map((item) => item.folder)
      .reduce((acc, curr) => {
        if (!acc.includes(curr)) {
          acc.push(curr)
        }
        return acc
      }, [] as string[])
  }, [services])

  const updateCodes = useCallback((services: Service[]) => {
    return services.map((service) => {
      const code = totpGenerator(service.secret)
      return {
        code: `${code.substring(0, 3)} ${code.substring(3)}`,
        name: service.name,
      }
    })
  }, [])

  function addSamples() {
    const samples: Service[] = [
      {
        name: "Google",
        id: "google-totp",
        secret:
          "W2VXQ4PX2GLJYY4UUUPM4Y5ZZKJMQHDXRFCPU6THFIHTBPXANQVMELOA7XWSGCCU",
        issuer: "Google",
        folder: "work",
      },
      {
        name: "Amazon Web Services",
        id: "aws-totp",
        secret:
          "W2VXQ4PX2GLJYY4UUUPM4YKZZKJMQHDXRFCPU6BHFIHTBPXGNQVMELOA7XWSGCCU",
        issuer: "Amazon Web Services",
        folder: "work",
      },
      {
        name: "GitHub",
        id: "github-totp",
        secret:
          "W2VXQ4PX2GLJYY4UUUPM4YKZZKJMQHDXRFCPU6BHFIHTBPXANQVMELRA7XWSGCCU",
        issuer: "GitHub",
        folder: "work",
      },
      {
        name: "GitLab",
        id: "gitlab-totp",
        secret:
          "W2VXQ4PX2GLJYY4UUUPM4YKZZKJMQHRXRFCPU6BHFIHTBPXANQVMELOA7XWSGCCU",
        issuer: "GitLab",
        folder: "work",
      },
    ]
    setServices([...services, ...samples])
    toast.success("Samples added")

    const data =
      "otpauth-migration://offline?data=Cj0KFCVf3YWL6lh1bFXjDfqYnhGGD8a2Ehd2aWNrZS5oYW5zc29uQGdtYWlsLmNvbRoGR29vZ2xlIAEoATACCkQKEGVJMlpoWXJ6WFpCc2ZYY0QSFyhEZXYpIG1ybWludXRAbWludXQuY29tGhFNaW51dCBEZXZlbG9wbWVudCABKAEwAgpEChBHWkJjQ1hva3V0T0NSbld0EhcoRGV2KSBtcm1pbnV0QG1pbnV0LmNvbRoRTWludXQgRGV2ZWxvcG1lbnQgASgBMAIKRAoQNWRkSTQ4ZnVKeDlKZm13UBIXKERldikgbXJtaW51dEBtaW51dC5jb20aEU1pbnV0IERldmVsb3BtZW50IAEoATACEAEYASAA"
    GoogleMigrator(data)
  }

  useEffect(() => {
    setCodes(updateCodes(services))
    const time = getTimeToNextRefresh()

    setTimeLeft(time)
  }, [services, updateCodes])

  useEffect(() => {
    localStorage.setItem(
      "services",
      JSON.stringify(
        services.map((service) => {
          return service
        })
      )
    )

    if (folders.length > 0) {
      setSelectedFolder(folders[0])
    }

    const codes = updateCodes(services)
    setCodes(codes)
  }, [folders, services, services.length, updateCodes])

  useEffect(() => {
    if (timeLeft === null) {
      return
    }

    if (timeLeft === 0) {
      if (timebarRef.current !== null) {
        timebarRef.current.style.transition = "none"
      }
      setTimeLeft(30)
      setTimeout(() => {
        if (timebarRef.current !== null) {
          timebarRef.current.style.transition = "width 1s linear"
        }
      }, 500)
      console.log("Time window expired, update codes")
      const codes = updateCodes(services)
      setCodes(codes)

      return
    }

    if (!timeout.current) {
      //console.log("Setting timeout " + timeLeft)
      timeout.current = setTimeout(() => {
        const time = getTimeToNextRefresh()
        setTimeLeft(time)
        timeout.current = null
      }, 1000)
    }
  }, [services, timeLeft, updateCodes])

  return (
    <div className="font-sans w-full bg-neutral-800  text-white select-none ">
      <>
        <div>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
        <div className="space-y-2 pt-[10px] flex flex-col">
          <div className="mx-3 flex flex-row mt-3 mb-3">
            <h1
              onClick={addSamples}
              className="text-2xl align-middle font-bold mx-2 grow sm:truncate sm:text-3xl sm:tracking-tight"
            >
              Authenticator
            </h1>
            <div className="place-self-end flex flex-row space-x-3">
              <Cog8ToothIcon
                onClick={() => setShowSettings(true)}
                className="w-[30px]"
              />
              <PlusCircleIcon
                className="w-[30px]"
                onClick={() => setShowAdd(true)}
              />
            </div>
          </div>
          {false && <p>Time remaining: {timeLeft}s</p>}
          <div
            ref={timebarRef}
            className="bg-blue-400"
            style={{
              height: "5px",
              width: ((timeLeft ?? 30) / 30) * 100 + "%",
              transition: "width 1s linear",
            }}
          ></div>
        </div>
        {folders.length > 1 && (
          <div className="flex flex-row items-center justify-center py-3">
            {folders.map((folder) => (
              <p
                className={
                  "grow text-center decoration-blue-400 decoration-2 underline-offset-4 " +
                  (selectedFolder === folder
                    ? " text-white underline"
                    : "text-slate-500")
                }
                key={folder}
                onClick={() => setSelectedFolder(folder)}
              >
                {`${folder.substring(0, 1).toUpperCase()}${folder.substring(
                  1
                )}`}
              </p>
            ))}
          </div>
        )}
        <div>
          {services &&
            services
              .filter((service) => {
                return service.folder === selectedFolder
              })
              .map((service) => (
                <div
                  key={service.id}
                  className="space-x-5 px-[15px] py-[10px] border-b-[1px] items-center flex flex-row border-slate-600"
                >
                  <div className="flex flex-col grow">
                    <p>{service.name}</p>
                    <div className="flex flex-row mt-2">
                      <p className="text-xs grow align-middle leading-8">
                        viktor.hansson@me.com
                      </p>
                      <p className="text-3xl leading-10">
                        {codes.find((code) => code.name === service.name)?.code}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        {showAdd && (
          <AddService
            services={services}
            close={() => {
              setShowAdd(false)
            }}
            setServices={setServices}
          />
        )}

        {showSettings && (
          <Settings
            setServices={setServices}
            close={() => setShowSettings(false)}
          />
        )}
      </>
    </div>
  )
}
