import toast, { Toaster } from "react-hot-toast"
import { Service } from "./App"

function DeleteServices() {
  localStorage.setItem("services", JSON.stringify([]))
}

export function Settings(props: {
  close: () => void
  setServices: (args: Service[]) => void
}) {
  return (
    <>
      <div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
      <div
        className="font-sans w-full bg-neutral-800  text-white select-none "
        style={{ position: "fixed", width: "100vw", height: "100vh", top: "0" }}
      >
        <>
          <div className="space-y-2 pt-[10px] flex flex-col">
            <div className="mx-3 flex flex-row mt-3 mb-3">
              <h1
                onClick={props.close}
                className="text-2xl align-middle font-bold mx-2 grow sm:truncate sm:text-3xl sm:tracking-tight"
              >
                Settings
              </h1>
              <p onClick={props.close} className="text-blue-400 self-center">
                Done
              </p>
            </div>
          </div>
          <div className="flex flex-row grow justify-center mt-5">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded"
              onClick={() => {
                props.setServices([])
                DeleteServices()
                toast.success("All services deleted")
              }}
            >
              Delete all items
            </button>
          </div>
        </>
      </div>
    </>
  )
}
