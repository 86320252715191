import { useCallback, useEffect, useRef, useState } from "react"
import "./add-service.css"
import QrScanner from "qr-scanner"
import { Service } from "./App"
import { ReactComponent as CloseButton } from "./close.svg"
import { GoogleMigrator } from "./google-auth-import"
import toast, { Toaster } from "react-hot-toast"
import { Spinner } from "./spinner"

const ParseOtp = require("otpauth-uri-parser")

export async function sha256(message: string): Promise<string> {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

interface ParsedOtp {
  type: "totp"
  label: {
    issuer: string
    account: string
  }
  query: {
    secret: string
    issuer: string
  }
}

export function AddService(props: {
  services: Service[]
  setServices: any
  close: () => void
}) {
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const scannerRef = useRef<QrScanner | null>(null)
  const [result, setResult] = useState<string | null>(null)

  const handleClose = useCallback(() => {
    scannerRef.current?.stop()
    scannerRef.current?.destroy()
    scannerRef.current = null
    props.close()
  }, [props])

  const addNewService = useCallback(async (parsed: ParsedOtp) => {
    const id = await sha256(
      `${parsed.label.account}-${parsed.query.issuer}-${parsed.query.secret}`
    )
    if (props.services.find((service) => service.id === id)) {
      toast.error("Service already exists")
      return
    }
    const newService: Service = {
      name: parsed.label.account,
      secret: parsed.query.secret,
      issuer: parsed.query.issuer,
      id,
      folder: "personal",
    }
    props.setServices([...props.services, newService])
  }, [])

  const importGoogle = useCallback(async (result: string) => {
    const services = await GoogleMigrator(result)
    props.setServices([...props.services, ...services])
  }, [])

  async function startCamera(scanner: QrScanner) {
    console.log("starting camera")
    await scanner.start()
    console.log("camera started")
  }

  useEffect(() => {
    if (result?.includes("otpauth")) {
      if (result?.includes("migration")) {
        importGoogle(result)
        handleClose()
        return
      } else {
        const parsed = ParseOtp(result)
        if (parsed?.type !== "totp") {
          toast.error("QR code is not a valid TOTP code")
          handleClose()
          return
        }
        //{type: "totp", label: {issuer: "", account: "AWS viktor.hansson@me.com"}, query: {secret: "jhkjjk", issuer: "Amazon Web Services"}}
        addNewService(parsed)
        setTimeout(handleClose, 3000)
      }
    }
  }, [handleClose, props, result])

  useEffect(() => {
    if (videoElementRef.current && scannerRef.current === null) {
      console.log("Creating scanner")

      /*
      setTimeout(() => {
        setResult(
          "otpauth://totp/AWS viktor.hansson@me.com?secret=W2VXQ4PX2GLJYY4UUUPM4YKZZKJMQHDXRFCPU6BHFIHTBPXANQVMELOA7XWSGCCU&issuer=Amazon Web Services"
        )
      }, 1000)
      */

      scannerRef.current = new QrScanner(
        videoElementRef.current,
        (result: QrScanner.ScanResult) => {
          console.log("decoded qr code:", result.data?.toString())
          setResult(result.data?.toString())
        },
        {
          maxScansPerSecond: 5,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          calculateScanRegion: (video: HTMLVideoElement) => {
            const smallestDimension = Math.min(
              video.videoWidth,
              video.videoHeight
            )
            const scanRegionSize = Math.round((1 / 2) * smallestDimension)
            //toast(`Width: ${video.videoWidth} Height: ${video.videoHeight}`)
            //toast(`Scan region size: ${scanRegionSize}`)
            return {
              x: Math.round((video.videoWidth - scanRegionSize) / 2),
              y: Math.round((video.videoHeight - scanRegionSize) / 2),
              width: scanRegionSize,
              height: scanRegionSize,
            }
          },
        }
      )
      scannerRef.current.setInversionMode("both")
      startCamera(scannerRef.current)
    }
  }, [videoElementRef])

  //otpauth://totp/AWS viktor.hansson@me.com?secret=W2VXQ4PX2GLJYY4UUUPM4YKZZKJMQHDXRFCPU6BHFIHTBPXANQVMELOA7XWSGCCU&issuer=Amazon Web Services

  return (
    <div className="wrapper bg-neutral-800">
      <div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
      <Spinner />
      <video id="camera" ref={videoElementRef}></video>
      <div className="overlay" ref={overlayRef}>
        <CloseButton onClick={handleClose} className="closeButton" />
        {false && (
          <div className="crosshairs">
            <div id="one"></div>
            <div id="two"></div>
            <div id="three"></div>
            <div id="four"></div>
          </div>
        )}
      </div>
    </div>
  )
}
