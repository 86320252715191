import protobufjs from "protobufjs"
import { sha256 } from "./add-service";
import { Service } from "./App";
const { base32 } = require("rfc4648");

interface BackupPayload {
  otpParameters: {
    digits: number
    issuer: string
    secret: string
    type: number
    name: string
  }[]
}

const protocol = `
syntax = "proto3";

message MigrationPayload {
  enum Algorithm {
    ALGORITHM_UNSPECIFIED = 0;
    ALGORITHM_SHA1 = 1;
    ALGORITHM_SHA256 = 2;
    ALGORITHM_SHA512 = 3;
    ALGORITHM_MD5 = 4;
  }

  enum DigitCount {
    DIGIT_COUNT_UNSPECIFIED = 0;
    DIGIT_COUNT_SIX = 1;
    DIGIT_COUNT_EIGHT = 2;
  }

  enum OtpType {
    OTP_TYPE_UNSPECIFIED = 0;
    OTP_TYPE_HOTP = 1;
    OTP_TYPE_TOTP = 2;
  }

  message OtpParameters {
    bytes secret = 1;
    string name = 2;
    string issuer = 3;
    Algorithm algorithm = 4;
    DigitCount digits = 5;
    OtpType type = 6;
    int64 counter = 7;
  }

  repeated OtpParameters otp_parameters = 1;
  int32 version = 2;
  int32 batch_size = 3;
  int32 batch_index = 4;
  int32 batch_id = 5;
}
`

function base64ToArrayBuffer(base64: string): Uint8Array {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}


export async function GoogleMigrator(data: string): Promise<Service[]> {
  const sourceData = new URL(data).searchParams.get("data")

  const protobufRoot = protobufjs.parse(protocol).root

  const migrationPayload = protobufRoot.lookupType("MigrationPayload");
  const decodedOtpPayload: BackupPayload = migrationPayload.decode(
    base64ToArrayBuffer(sourceData ?? "")
  ) as unknown as BackupPayload

  const otpParameters: Service[] = []
  for (let otpParameter of decodedOtpPayload.otpParameters) {
    if (otpParameter.type !== 2 || otpParameter.digits !== 1) {
      alert(`Unsupported OTP: ${otpParameter.name}`)
      continue
    }
    otpParameters.push({
      secret: base32.stringify(otpParameter.secret),
      name: otpParameter.name,
      issuer: otpParameter.issuer,
      id: await sha256(
        `${otpParameter.name}-${otpParameter.issuer}-${base32.stringify(otpParameter.secret)}`
      ),
      folder: "Google auth"
    })
  }

  return otpParameters
}